import React from "react"
import { Link } from "gatsby"

const Logo = props => (
  <div className="site-logo">
    <Link to="/">
      <img alt="Undebtme logo" src="/assets/undebtme-logo.png" />
    </Link>
  </div>
)

export default Logo
